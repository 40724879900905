import { getShopifyImage } from 'gatsby-source-shopify';
import { Base64 } from 'js-base64'
import { filterVariantForWFT } from 'lib/shopify';
import { GatsbyShopifyProduct, GatsbyShopifyStorefrontProduct, ShopifyCheckout, ShopifyProduct } from 'lib/shopify/storefront-api-client/types/custom.types';
import { MoneyV2, Image, Maybe } from 'lib/shopify/storefront-api-client/types/storefront.types';
import { Discount, ProductCopy } from 'types/misc';

export const encodeId = (str: string) => {
  return Base64.encode(str);
}

type VariantLike = {
  sku: string, 
  availableForSale: boolean
}

export const isProductDiscounted = (discount: Discount, product: { variants: VariantLike[] }) => {
  return discount.products?.some(p => product.variants.some(v => p.sku === v.sku && v.availableForSale));
}

/**
 * High leve util to just decode an ID, if not base64 just returns string
 */
export const decodeId = (str: string) => {
  return Base64.isValid(str) ? Base64.decode(str) : str;
}

// Encoding for shopify IDs
export const encodeShopifyId = (id) => {
  const shopifyIdUrl = 'gid://shopify/Product'
  return Base64.encode(`${shopifyIdUrl}/${id}`)
}

// Encoding for shopify variant  IDs
export const encodeShopifyVariantId = (id) => {
  const shopifyIdUrl = 'gid://shopify/ProductVariant'
  return Base64.encode(`${shopifyIdUrl}/${id}`)
}

/**
 * input: gid://shopify/ProductVariant/31979955552291'
 * output: '31979955552291'
 */
 export const fetchIdFromGlobalId = (id: string|number) => {
  return String(id).split('/').slice(-1)[0]
}

export const fetchIdFromGlobalLineItemId = (id: string|number) => {
  return String(id)
    .split('/')
    .slice(-1)[0]
    .split('?')[0]
}

export const getGlobalVariantId = (strippedVariantId: string|number) => {
  return `gid://shopify/ProductVariant/${strippedVariantId}`;
}

export const getGlobalProductId = (strippedProductId: string|number) => {
  return `gid://shopify/Product/${strippedProductId}`;
}

export const transformShopifyMediaImages = (
  shopifyProduct: GatsbyShopifyProduct, 
  imageTransformationSettings?: { width: number, height: number  }
) => {
  const copyProduct = { ...shopifyProduct };

  copyProduct.images = shopifyProduct.media.map(
    ({ id, image }) => { 
      const copyImage = {
        ...image, 
        id
      }

      /**
       * convert .gif images since gatsby-image-plugin doesn't support .gif image transformation
       * and therefore gatsbyImageData return null
       * @source https://github.com/gatsbyjs/gatsby/issues/23598
       */
      if (!copyImage.gatsbyImageData) {
        copyImage.gatsbyImageData = getShopifyImage({
          image: {
            height: imageTransformationSettings?.width || 1600,
            width: imageTransformationSettings?.height || 1600,
            originalSrc: copyImage.originalSrc
          },
          width: imageTransformationSettings?.width || 1600,
          height: imageTransformationSettings?.height || 1600
        })
      }

      return copyImage;
    }
  );

  return copyProduct;
}

type VariantPriceLike = {
  price: MoneyV2
}
const sortByPrice = (a: VariantPriceLike, b: VariantPriceLike) => Number(a.price.amount) < Number(b.price.amount) ? -1 : 1;

export const transformGatsbyShopifyVariants = (shopifyProduct) => {
  const copyProduct = { ...shopifyProduct };

  copyProduct.variants = copyProduct.variants.map((variant) => {
    if (!variant.price.amount) {
      variant.price = {
        amount: String(variant.price),
        currencyCode: 'USD'
      }
    }

    return variant;
  }).sort(sortByPrice);

  return copyProduct
}

export const transformGatsbyShopifyProduct = (
  shopifyProduct: GatsbyShopifyProduct, 
  imageTransformationSettings?: { width: number, height: number  }
) => {
  return transformShopifyMediaImages(
    transformGatsbyShopifyVariants(
      shopifyProduct
    ),
    imageTransformationSettings
  )
}

export const transformShopifyStorefrontProduct = (shopifyProduct: ShopifyProduct, productPage: ProductCopy) => {
  if (shopifyProduct?.availableForSale) {
    const variantsFiltered = transformShopifyStorefrontImagesToGatsbyImage({
      ...shopifyProduct,
      variants: filterVariantForWFT(shopifyProduct.variants)
    });

    if (variantsFiltered) {
      return {
        ...variantsFiltered,
        copy: productPage
      }
    }
  }

  return null;
}

export const transformShopifyStorefrontImagesToGatsbyImage = (
  product: ShopifyProduct,
  width?: number,
  height?: number
): GatsbyShopifyStorefrontProduct | null => {
  if (product?.images) {
    const copyOfProduct = { ...product, images: [] } as GatsbyShopifyStorefrontProduct
    const transformImage = (img: Image) => ({
      id: String(img.id),
      originalSrc: img.src || img.originalSrc,
      gatsbyImageData: getShopifyImage({
        image: {
          height: img.height,
          width: img.width,
          originalSrc: img.src || img.originalSrc
        },
        width,
        height
      })
    })
    const gatsbyImages = product?.images.map(transformImage);

    product?.variants
      .filter(v => v.image)
      .forEach(v => {
        const imgIdx = gatsbyImages.findIndex(img => img.id === v.image.id);
        if (imgIdx !== -1) {
          gatsbyImages[imgIdx] = {
            ...gatsbyImages[imgIdx],
            variantIds: (
              gatsbyImages[imgIdx].variantIds?.length 
                ? [...gatsbyImages[imgIdx].variantIds, v.id]
                : [v.id]
            )
          }
        }
    })

    copyOfProduct.images = gatsbyImages;

    return copyOfProduct;
  }

  return null;
}

export const sortVariantsByPrice = (variants: VariantPriceLike[]) => (
  variants.sort(sortByPrice)
)

export enum DISOUNT_TEMPLATE_KEYWORDS {
  TIERED = 'TIERED',
  GIFT = 'GIFT'
}

export const isTieredDiscountApplied = (checkout?: ShopifyCheckout) => {
  if (!checkout?.discountApplications) return false;
  return checkout.discountApplications.find(
    d => {
      if (!d.code) return false;

      return d.code.includes(DISOUNT_TEMPLATE_KEYWORDS.TIERED) && d.applicable;
    }
  )
}

export const isFreeGiftApplied = (checkout?: ShopifyCheckout) => {
  if (!checkout?.discountApplications) return false;
  const isApplied = Boolean(checkout.discountApplications.find(
    d => {
      if (!d.code) return false;

      return d.code.includes(DISOUNT_TEMPLATE_KEYWORDS.GIFT) && d.applicable;
    }
  ))

  return isApplied
}

export const getTotalQuantityOfProductInCart = (
  variants: { id: string }[], 
  checkout?: ShopifyCheckout
) => {
  if (!checkout || !checkout?.lineItems.length) return 0;

  const variantIds = variants.map(v => v.id);

  return checkout.lineItems.reduce((prev, lineItem) => (
    variantIds.includes(lineItem.variant.id) 
      ? (prev + lineItem.quantity) 
      : prev
  ), 0)
}

export const isVariantInCart = (variantId: string, checkout?: ShopifyCheckout) => {
  if (!checkout) return false;

  return Boolean(
    checkout.lineItems.find(lineItem => lineItem.variant.id === variantId)
  )
}

export const isServiceProduct = (product: { tags?: string[] }) => {
  if (!product.tags) return false;
  return product.tags.includes('Service');
}

export const isValidCompareAtPrice = (variant: { compareAtPrice?: Maybe<MoneyV2> }) => {
  return Boolean(variant.compareAtPrice && Number(variant.compareAtPrice.amount) !== 0);
}


export const MAX_LINE_ITEM_QUANTITY = 15;