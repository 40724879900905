import { QuantityInput } from "components/inputs"
import styled from "styled-components"
import { Price } from "./Price"
import React from "react";
import { MAX_LINE_ITEM_QUANTITY, getBasePrice } from "utilities";
import { useDiscount } from "global";
import { ShopifyProductVariant } from "lib/shopify/storefront-api-client/types/custom.types";

type Props = {
  selectedVariant: ShopifyProductVariant
  setCurrentQuantity: (qty: number) => void
  currentQuantity: number
  upsellText?: string
  hidePrice?: boolean
  minAmount?: number
}
export const QuantitySelector = ({
  selectedVariant,
  setCurrentQuantity,
  currentQuantity,
  upsellText,
  hidePrice,
  minAmount = 1
}: Props) => {
  const { getDiscountAmount } = useDiscount();

  return (
    <Container hidePrice={hidePrice} className="quanity-selector-container">
      <div className='quantity-input-wrapper'>
        <div className='center-text'>
          <div className="quantity-title">Quantity</div>
          <QuantityInput
            minAmount={minAmount}
            limit={MAX_LINE_ITEM_QUANTITY}
            increment={() => setCurrentQuantity(currentQuantity + 1)}
            decrement={() => setCurrentQuantity(currentQuantity === 0 ? 0 : currentQuantity - 1)}
            selectedAmount={currentQuantity}
          />
        </div>   
        {!hidePrice && 
          (
            <div className="price-text-wrapper">
              <div>Subtotal</div>
              <Price 
                forceShow
                basePrice={getBasePrice(selectedVariant) * currentQuantity}
                discountAmount={getDiscountAmount(selectedVariant) * currentQuantity}
              />
            </div>
          )
        }
      </div>
      {upsellText && <p className="no-top-margin center-text">{upsellText}</p>}
    </Container>
  )
}

const Container = styled.div<{ hidePrice?: boolean }>`
  .quantity-input-wrapper {
    display: flex;
    justify-content: ${({ hidePrice }) => !hidePrice ? 'space-between' : 'center'};
    align-items: center;
    margin-bottom: 20px;

    .price, .discount {
      font-family: var(--headlineFont);
      text-align: center;
      font-size: var(--largestFontSize);
      line-height: 30px;
      font-weight:  var(--fontWeightNormal);
    }

    .price-text-wrapper {
      text-align: right;
    }
  }

  .quantity {
    background-color: white;
  }
`