import { Button } from "@rachio-npm/rachio-retail-common";
import React from "react";
import {
  ShopifyCopyStorefrontProduct,
} from "utilities";
import { Section } from "components/sections";
import { CollectionCalloutCard } from "components/collection/CollectionCalloutCard";
import styled from "styled-components";
import { below } from "styles";
import { PickyStoryDeal } from "../../../lib/pickystory";
import { BundleProductCard } from "./BundleProductCard";
import { Price } from "components/product";
import { PortableText } from "components/elements";
import { SectionPaddingSize, SectionSize, SectionTheme } from "types/misc";
import { QuanitifiedShopifyProductVariant } from "./BundleDisplay";

type BundleProductListOverviewProps = {
  deal: PickyStoryDeal
  products: (ShopifyCopyStorefrontProduct | null)[],
  addonProducts?: (ShopifyCopyStorefrontProduct | null)[],
  onVariantSelect: (globalProductId: string, variant: QuanitifiedShopifyProductVariant) => void,
  onAddonVariantSelect?: (globalProductId: string, variant: QuanitifiedShopifyProductVariant) => void,
  onAddBundleToCart: () => void,
  subTotal: number | null,
  discountAmount: number,
  addToCartButtonText: string
  copy: {
    title: string,
    shortDescription: string,
    _rawDescription: any[],
    enableQuantitySelectionForBogoAddonProducts?: boolean
    bogoAddonProductsOptional?: boolean
  }
  hideTitle?: boolean,
  theme?: SectionTheme,
  paddingTop?: SectionPaddingSize
  paddingBottom?: SectionPaddingSize,
  size?: SectionSize
}

export const BundleProductListOverview = (
  {
    deal,
    addonProducts,
    products,
    copy,
    onVariantSelect,
    onAddonVariantSelect,
    onAddBundleToCart,
    subTotal,
    discountAmount,
    addToCartButtonText,
    hideTitle,
    theme,
    paddingBottom,
    paddingTop,
    size
  }: BundleProductListOverviewProps
) => {
  return (
    <BundleProductListOverviewContainer className='Section--product-bundle'>
      <Section
        id='product-bundle-section'
        paddingTop={paddingTop || 'small'}
        theme={theme || 'white'}
        paddingBottom={paddingBottom || 'small'}
        size={size || 'small'}
      >
        <div data-testid="bundle-product-overview">
          {!hideTitle && (<div style={{ textAlign: 'center' }}>
            <h1 className="no-top-margin">
              {copy.title}
            </h1>
          </div>)}

          <BundleCardGrid productLength={products.length + (addonProducts ? addonProducts.length : 0)}>
            {products.map((product) => (product &&
              <BundleProductCard
                product={product}
                copy={product.copy}
                onVariantSelect={onVariantSelect}
                key={product.id}
                hidePrice={true}
              />
            ))}
            {addonProducts && onAddonVariantSelect && addonProducts.map((product) => (product &&
              <BundleProductCard
                product={product}
                copy={product.copy}
                onVariantSelect={onAddonVariantSelect}
                key={product.id}
                hidePrice={true}
                quantitySelectionEnabled={copy.enableQuantitySelectionForBogoAddonProducts}
                productOptional={copy.bogoAddonProductsOptional}
              />
            ))}
            <CustomCalloutCardStyles>
              <CollectionCalloutCard
                headline={copy.title}
                hideOnMobile={false}
              >
                <div className="add-to-cart">
                  <PortableText _rawContent={copy._rawDescription} />
                  {subTotal && (
                    <Price
                      basePrice={subTotal}
                      discountAmount={discountAmount}
                      forceShow={true}
                    />
                  )}
                  <Button onClick={onAddBundleToCart} data-testid="bundle-overview-buy-button">
                    {addToCartButtonText}
                  </Button>
                </div>
              </CollectionCalloutCard>
            </CustomCalloutCardStyles>

          </BundleCardGrid>
        </div>
      </Section>
    </BundleProductListOverviewContainer>
  )
}

const BundleCardGrid = ({ children, productLength }) => {
  if (productLength > 3) {
    return (
      <MaxColumnGrid>
        {children}
      </MaxColumnGrid>
    )
  }

  if (productLength === 3) {
    return (
      <FourColumnGrid>
        {children}
      </FourColumnGrid>
    )
  }

  return (
    <ThreeColumnGrid>
      {children}
    </ThreeColumnGrid>
  )
}

const BundleProductListOverviewContainer = styled.div`
  .deal-price-text {
    font-size: var(--largestFontSize);
    .compare-at-price {
      text-decoration: line-through;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
  .add-to-cart {
    max-width: 480px;
    margin: 0 auto;
    p:first-child {
      margin-top: 0;
    }
  }

  ${below.small`
    h1 {
      font-size: var(--h2);
    }
  `}
`

const CustomCalloutCardStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .product-wrapper {
    background: transparent;
    padding: 1rem;
    height: 100%;
    
    h2, p {
      color: black;
    }
    .discount {
      color: var(--accentColor);
    }
    .product-title {
      margin-top: 0;
      color: var(--primaryColor);
    }
    .product-description {
      min-height: auto;
      margin-bottom: 2rem;
    }
    
    button {
      margin: 0 auto;
      width: 100%;
      padding: 1rem 5rem;
    }
  }

  .pricing {
    .price,
    .discount {
      font-size: 18px;
    }
  }

  .price-text {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: var(--largestFontSize);
    .price {
      color: black;
      margin-right: 5px;
    }
  }

  ${below.small`
    margin: 0;
    .product-wrapper {
      padding: 40px 0;
    }

    .pricing p {
      font-size: 1.5rem;
    }
  `}
`

const ThreeColumnGrid = styled.div`
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1199px) {
    gap: 1rem;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    > div:last-child {
      grid-column: span 2;
    }
  }

  @media (max-width: 380px) {
    grid-template-columns: 1fr;
    > div:last-child {
      grid-column: span 1;
    }
  }
`

const FourColumnGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
    > div:last-child {
      grid-column: span 3;
    }
  }

  @media (max-width: 767px) {
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    > div:last-child {
      grid-column: span 2;
    }
  }

  @media (max-width: 380px) {
    grid-template-columns: 1fr;
    > div:last-child {
      grid-column: span 1;
    }
  }
`

const MaxColumnGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  > div:last-child {
    grid-column: span 4;
  }

  @media (max-width: 767px) {
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    > div:last-child {
      grid-column: span 2;
    }
  }

  @media (max-width: 380px) {
    grid-template-columns: 1fr;
    > div:last-child {
      grid-column: span 1;
    }
  }
`