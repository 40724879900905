import { Modal } from "components/elements";
import React from "react";
import styled from "styled-components";
import { GatsbyShopifyStorefrontCopyUpsellProduct } from "types/misc";
import { ProductCarousel } from "../ProductCarousel";

type Props = {
  product: GatsbyShopifyStorefrontCopyUpsellProduct | undefined | null,
  onClose: () => void
  isActive: boolean
}

export const ProductUpsellInfoModal = ({ isActive, product, onClose }: Props) => {
  return (
    <Modal 
      closeAction={onClose}
      isActive={isActive}
    >
      {product && (
        <>
          <CarouselWrapper>
            <ProductCarousel
              copyProduct={product}
              productImages={product.images}
              enableShare={false}
            />
          </CarouselWrapper>
          
          <div className='padding'>
            <h2 className="no-margins">{product.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
          </div>
        </>
      )}
    </Modal>
  )
}

const CarouselWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
`