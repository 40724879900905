import React, { useEffect, useState } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Grid, below } from 'styles'
import { ShopifyCopyProduct } from 'utilities'
import ProductBadge from './ProductBadge'
import { ProductShareButton } from './ProductShareButton'
import { ShareButton } from 'components/elements'
import { trackProductShared } from '../../analytics/events/ecommerce/product-shared'

type ProductImage = {
  id: string
  gatsbyImageData: IGatsbyImageData
  altText?: string
}

type Props = {
  productImages: ProductImage[]
  activeImageIndex?: number
  copyProduct?: ShopifyCopyProduct
  enableShare?: boolean
}

export function ProductCarousel({ productImages, copyProduct, activeImageIndex, enableShare = true }: Props) {
  if (!productImages) return null

  const [[image, imageIndex], setImage] = useState([productImages[0], 0])

  const changeImage = (index: number) => {
    setImage([productImages[index], index])
  }

  useEffect(() => {
    if (typeof activeImageIndex === 'number') {
      setImage([productImages[activeImageIndex], activeImageIndex])
    }
  }, [activeImageIndex])

  const handleSuccessfulShare = (shareData: { text: string, url: string, type: string }) => {
    trackProductShared({
      title: shareData.text,
      url: shareData.url,
      type: shareData.type
    })
  }

  return (
    <div style={{ marginBottom: productImages.length > 1 ? '0px' : '20px' }}>
      <ImageContainer>
        {copyProduct && copyProduct.copy.badgeEnabled && (
          <ProductBadge
            product={copyProduct}
          />
        )}
        {enableShare ? (
          copyProduct ? (
            <ProductShareButton
              product={copyProduct}
              onSuccessfulShare={handleSuccessfulShare}
            />
          ) : (
            <ShareButton 
              text='Check out this product on rachio.com!'
              url={location.href}
              onSuccessfulShare={handleSuccessfulShare}
            />
          )
        ) : null}
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.altText || copyProduct?.title || 'main image carousel'}
          objectFit="contain"
        />
      </ImageContainer>

      {productImages.length > 1 && (
        <ThumbnailGrid cols={productImages.length}>
          {productImages.map((img, index) => (
            <ThumbnailContainer
              isActive={index === imageIndex}
              key={img.id}
              onClick={() => changeImage(index)}
            >
              <GatsbyImage
                image={img.gatsbyImageData}
                alt={`thumbnail`}
                objectFit="contain"
              />
            </ThumbnailContainer>
          ))}
        </ThumbnailGrid>
      )}
    </div>
  )
}

const ImageContainer = styled.div`
  position: relative;
  .product-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 1.2rem;
  }
  .btn-share {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
  margin: calc(var(--margin) * 2) 0px calc(var(--margin) / 2) 0px;
  .gatsby-image-wrapper {
    border-radius: var(--cardRadius);
    isolation: isolate;
    border: 1px solid var(--lineColor);
  }

  overflow: hidden;
  display: flex;
  justify-content: center;
  ${below.medium`
   margin:  0px;
  `}
`

const ThumbnailGrid = styled(Grid)`
  margin: var(--margin) 0;
  grid-gap: 10px;
`

const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  isolation: isolate;
  overflow: hidden;
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00a7e1;
        opacity: 0.2;
      }
    `}
`
